<template>
  <div class="mt-3">
    <v-row no-gutters>
      <v-autocomplete
        v-model="itemClass"
        :items="classSelectedList"
        :label="$t('subjects.choose_class')"
        :item-text="item => `${item.grade} / ${item.name}`"
        return-object
        dense
        outlined
        hide-details
        @change="getStudent"
      />
      <v-spacer></v-spacer>
      <v-text-field
        v-model="searchStudent"
        append-icon="mdi-magnify"
        :label="`${$t('app.search')} ${$t('app.student')}`"
        single-line
        hide-details
        dense
        outlined
      ></v-text-field>
    </v-row>

    <v-data-table
      v-model="selectedStudent"
      :headers="tableHeaders"
      :items="studentsList"
      :loading="loading"
      :single-select="!someStudent"
      :search="searchStudent"
      fixed-header
      height="290"
      item-key="person"
      hide-default-footer
      disable-pagination
      show-select
      class="elevation-0 my-6"
    >
      <template v-slot:item.class>
        <div>{{ className }}</div>
      </template>
      <template #item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox v-if="!someStudent" :value="true" disabled />
        <v-simple-checkbox v-else :value="isSelected" @input="select($event)" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    classSelected: Array,
    classList: Array,
    studentsList: Array,
    loading: Boolean,
    idStudent: Array,
    reset: Boolean,
    someStudent: Number
  },
  data() {
    return {
      searchStudent: "",
      itemClass: {},
      classSelectedList: [],
      search: "",
      selectedStudent: [],
      className: "",
      tableHeaders: [
        {
          text: this.$i18n.t("app.student"),
          value: "name"
        },
        {
          text: this.$i18n.t("app.class"),
          value: "class"
        }
      ]
    };
  },
  watch: {
    reset(newValue) {
      if (newValue) {
        this.itemClass = {};
        this.selectedStudent = [];
      }
    },
    classSelected() {
      this.classSelectedList = [];
      this.classList.map(d => {
        if (this.classSelected.indexOf(d.id) != -1) {
          const temp = { ...d };
          this.classSelectedList.push(temp);
        }
      });
    },
    selectedStudent(newValue) {
      let indexClass = this.classSelected.indexOf(this.itemClass.id);
      this.idStudent[indexClass] = newValue;
    }
  },
  methods: {
    getStudent(itemClass) {
      this.itemClass = itemClass;
      this.className = `${itemClass.grade} / ${itemClass.name}`;
      this.$emit("getData", itemClass.id);
    }
  }
};
</script>
