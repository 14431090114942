<template>
  <div>
    <v-card class="mx-3">
      <v-card-text>
        <v-form ref="addTeacher" lazy-validation>
          <v-row>
            <v-col cols="12" sm="7">
              <v-autocomplete
                v-model="form.teacher"
                :rules="[v => v.length > 0 || $t('app.required_array')]"
                :items="dataTeacher"
                :label="$t('subjects.choose_teacher')"
                multiple
                item-text="name"
                item-value="id"
                dense
                outlined
                required
              >
                <template slot="prepend-item">
                  <v-list-item @click="handleClickAll(false)">
                    <v-list-item-action>
                      <v-checkbox :value="checkAllTeacher" />
                    </v-list-item-action>
                    <v-list-item-content
                      :class="checkAllTeacher && 'primary--text'"
                    >
                      {{ $t("app.select_all") }}
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template #selection="{ item, index }">
                  <span v-if="index === 0" class="mr-2">{{ item.name }}</span>
                  <span v-if="index === 1" class="grey--text">
                    (+{{ form.teacher.length - 1 }} {{ $t("app.others") }})
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="5">
              <v-select
                v-model="form.class"
                :rules="[v => v.length > 0 || $t('app.required_array')]"
                :items="classSelectedList"
                :label="$t('subjects.choose_class')"
                :item-text="item => `${item.grade} / ${item.name}`"
                item-value="id"
                multiple
                dense
                outlined
                required
              >
                <template v-slot:selection="{ item, index }">
                  <span v-if="index === 0" class="mr-2">{{
                    `${item.grade} / ${item.name}`
                  }}</span>
                  <span v-if="index === 1" class="grey--text">
                    (+{{ form.class.length - 1 }} {{ $t("subjects.others") }})
                  </span>
                </template>
                <template slot="prepend-item">
                  <v-list-item @click="handleClickAll(true)">
                    <v-list-item-action>
                      <v-checkbox :value="checkAllClass" />
                    </v-list-item-action>
                    <v-list-item-content
                      :class="checkAllClass && 'primary--text'"
                    >
                      {{ $t("app.select_all") }}
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <div class="d-flex mt-6">
                <v-spacer />
                <div v-if="editable">
                  <v-btn
                    dark
                    outlined
                    color="primary"
                    class="mr-3"
                    @click="resetForm"
                  >
                    {{ $t("app.cancel") }}
                  </v-btn>
                  <v-btn
                    :loading="loading"
                    dark
                    depressed
                    class="gradient-primary"
                    @click="submitEditHandler"
                  >
                    {{ $t("app.edit") }}
                  </v-btn>
                </div>
                <v-btn
                  v-else
                  :loading="loading"
                  dark
                  depressed
                  class="gradient-primary"
                  @click="submitHandler"
                >
                  {{ $t("app.add") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-data-table
      :headers="tableHeaders"
      :items="dataTable"
      :loading="loading"
      hide-default-footer
      disable-pagination
      class="elevation-0 my-6"
    >
      <template v-slot:item.teacher="{ item }">
        <span>{{ showValue(item, "teacher") }}</span>
      </template>
      <template v-slot:item.class="{ item }">
        <span>{{ showValue(item, "class") }}</span>
      </template>
      <template v-slot:item.action="{ item }">
        <div>
          <v-btn
            icon
            small
            dark
            class="primary mr-2"
            @click="editTeacher(item)"
          >
            <v-icon small>mdi-pencil-box-outline</v-icon>
          </v-btn>
          <v-btn icon small dark class="error" @click="deleteTeacher(item.idx)">
            <v-icon small>mdi-delete-forever</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    classList: Array,
    classSelected: Array,
    submit: Function,
    teacherList: Array,
    reset: Boolean
  },
  computed: {
    dataTeacher: {
      set(value) {
        this.teacherList = value;
      },
      get() {
        return this.teacherList;
      }
    }
  },
  data() {
    return {
      checkAllClass: false,
      checkAllTeacher: false,
      loading: false,
      editable: false,
      dataTable: [],
      classSelectedList: [],
      form: {
        teacher: [],
        class: []
      },
      tableHeaders: [
        {
          text: this.$i18n.t("subjects.teacher"),
          value: "teacher"
        },
        {
          text: this.$i18n.t("subjects.class"),
          value: "class",
          width: 150
        },
        { text: this.$i18n.t("subjects.action"), value: "action", width: 100 }
      ]
    };
  },
  watch: {
    "form.class"(nv) {
      if (this.classList.length > 0 && this.classList.length == nv.length) {
        this.checkAllClass = true;
      } else this.checkAllClass = false;
    },
    classSelected() {
      this.classSelectedList = [];
      this.classList.map(r => {
        if (this.classSelected.indexOf(r.id) != -1) {
          const temp = { ...r };
          temp.class = r.grade + " / " + r.name;
          this.classSelectedList.push(temp);
        }
      });
    },
    reset(newValue) {
      if (newValue) {
        this.dataTable = [];
        this.resetForm();
      }
    }
  },
  methods: {
    handleClickAll(isClass) {
      if (isClass) {
        this.$nextTick(() => {
          this.checkAllClass = !this.checkAllClass;
          if (this.checkAllClass) {
            this.form.class = this.classList.map(r => r.id);
          } else this.form.class = [];
        });
      } else {
        this.$nextTick(() => {
          this.checkAllTeacher = !this.checkAllTeacher;
          if (this.checkAllTeacher) {
            this.form.teacher = this.dataTeacher.map(r => r.id);
          } else this.form.teacher = [];
        });
      }
    },
    submitEditHandler() {
      if (this.$refs.addTeacher.validate()) {
        this.loading = true;
        const idx = this.form.idx;
        let table = this.dataTable;
        // available
        this.dataTeacher.map(v => {
          v.disabled = false;
        });
        // unavailable
        this.dataTeacher.map(v => {
          this.form.teacher.map(x => {
            if (x === v.id) v.disabled = true;
          });
        });

        setTimeout(() => {
          table[idx].teacher = this.form.teacher;
          table[idx].class = this.form.class;
          this.editable = false;
          this.resetForm();
        }, 1000);
      }
    },
    itemUnavailable() {
      // unavailable
      this.dataTable.map(d =>
        this.dataTeacher.map(v => {
          d.teacher.map(x => {
            if (x === v.id) v.disabled = true;
          });
        })
      );
    },
    editTeacher(data) {
      this.itemUnavailable();
      // available
      data.teacher.map(
        d => (this.dataTeacher.find(v => v.id === d).disabled = false)
      );
      if (data.class.length === this.classSelectedList.length) {
        this.checkAllClass = true;
      }
      this.editable = true;
      this.form = { ...data };
    },
    deleteTeacher(idx) {
      // available
      this.dataTable[idx].teacher.map(
        d => (this.dataTeacher.find(v => v.id === d).disabled = false)
      );
      const data = this.dataTable.splice(idx, 1);
      this.submit(data);
    },
    showValue(item, type) {
      if (type == "teacher") {
        const teacherString = this.dataTeacher
          .filter(d => item.teacher.find(v => v === d.id))
          .map(d => d.name);
        return teacherString.join(", ");
      }
      if (type == "class") {
        const classString = [];
        item.class.map(i => {
          classString.push(
            this.classSelectedList.filter(c => c.id == i)[0]?.class
          );
        });
        return classString.join(", ");
      }
    },
    submitHandler() {
      if (this.$refs.addTeacher.validate()) {
        this.loading = true;
        const data = { ...this.form };
        this.form.teacher.map(
          d => (this.dataTeacher.find(v => v.id === d).disabled = true)
        );
        setTimeout(() => {
          this.dataTable.push({ idx: this.dataTable.length, ...data });
          this.submit(this.dataTable);
          this.resetForm();
        }, 700);
      }
    },
    resetForm() {
      this.loading = false;
      this.editable = false;
      this.checkAllClass = false;
      this.itemUnavailable();
      this.form = {
        teacher: [],
        class: []
      };
      this.$refs.addTeacher.reset();
      this.$refs.addTeacher.resetValidation();
    }
  }
};
</script>
