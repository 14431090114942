<template>
  <v-row>
    <v-dialog
      v-model="open"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="scale-transition"
      persistent
      max-width="800"
    >
      <v-card flat>
        <v-card-title>
          {{ $t("subjects.added") }}
          {{ $t("subjects.subjects") }}
          <div style="position: absolute; right: 25px;">
            <v-icon :disabled="loadingSave" @click="close">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-card-text>
          <v-stepper
            v-model="stepPosition"
            :alt-labels="altLabels"
            class="elevation-0"
          >
            <v-divider />
            <v-stepper-header class="elevation-0">
              <v-stepper-step :complete="stepPosition > 1" step="1">
                <span>{{ $t("subjects.subjects") }}</span>
              </v-stepper-step>
              <v-divider />
              <v-stepper-step :complete="stepPosition > 2" step="2">
                <span>{{ $t("subjects.teacher") }}</span>
              </v-stepper-step>
              <v-divider v-if="!subjectsAllStudent" />
              <v-stepper-step v-if="!subjectsAllStudent" step="3">
                <span>{{ $t("subjects.student") }}</span>
              </v-stepper-step>
            </v-stepper-header>
            <v-divider />

            <v-stepper-items>
              <v-stepper-content step="1" class="stepper-content">
                <SubjectOne
                  :open="open"
                  :subjectList="subjectList"
                  :subject="subject"
                  :semesterList="semesterList"
                  :gradeList="gradeList"
                  :classList="classList"
                  :type="type"
                  :setAllClass="setAllClass"
                  :resetForm="reset"
                  :validate="validateFormOne"
                  @onChangeFilter="onChangeFilter"
                  @changeSubjects="onChangeSubjects"
                />
                <!-- @getClass="getClass" -->
                <v-divider class="my-4" />
                <div class="d-flex">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="gradient-primary"
                    depressed
                    dark
                    @click="nextStep(1)"
                  >
                    {{ $t("app.next") }}
                  </v-btn>
                </div>
              </v-stepper-content>

              <v-stepper-content step="2" class="stepper-content">
                <SubjectTwo
                  :type="type"
                  :submit="saveTeacher"
                  :classList="classList"
                  :classSelected="subject.class"
                  :teacherList="teacherList"
                  :reset="
                    reset || resetBySchoolYear || resetByGrade ? true : false
                  "
                />

                <div style="min-width: 100%">
                  <span v-if="minTeacher" class="error--text caption">
                    {{ $t("subjects.msg_step_two") }}
                  </span>
                  <div style="float: right">
                    <v-btn
                      @click="prevStep(2)"
                      outlined
                      dark
                      color="primary"
                      class="mr-2"
                      >{{ $t("app.back") }}</v-btn
                    >
                    <v-btn
                      v-if="subjectsAllStudent"
                      :loading="loadingSave"
                      color="gradient-primary"
                      depressed
                      dark
                      @click="saveSubject"
                    >
                      {{ $t("app.save") }}
                    </v-btn>
                    <v-btn
                      v-else
                      color="gradient-primary"
                      depressed
                      dark
                      @click="nextStep(2)"
                    >
                      {{ $t("app.next") }}
                    </v-btn>
                  </div>
                </div>
              </v-stepper-content>

              <v-stepper-content step="3" class="stepper-content">
                <SubjectThree
                  v-if="!subjectsAllStudent"
                  :classSelected="subject.class"
                  :classList="classList"
                  :studentsList="studentsDataList"
                  :loading="loadingSubjectThree"
                  :idStudent="tempIdStudent"
                  :reset="reset || resetByGrade || resetByGrade ? true : false"
                  :someStudent="subject.lesson_type"
                  @getData="getDataStudent"
                />

                <div class="d-flex">
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="loadingSave"
                    :dark="!loadingSave"
                    class="mr-2"
                    outlined
                    color="primary"
                    @click="prevStep(3)"
                    >{{ $t("app.back") }}</v-btn
                  >
                  <v-btn
                    :loading="loadingSave"
                    color="gradient-primary"
                    depressed
                    dark
                    @click="saveSubject"
                  >
                    {{ $t("app.save") }}
                  </v-btn>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import SubjectOne from "./components/SubjectOne";
import SubjectTwo from "./components/SubjectTwo";
import SubjectThree from "./components/SubjectThree";
// import { get_class_list } from "@/api/admin/schoolClass";
import { studentsList } from "@/api/admin/master/student";
import { submitSubjects } from "@/api/admin/academic/subjects";
import { get_teacher } from "@/api/admin/academic/classList";

export default {
  components: {
    SubjectOne,
    SubjectTwo,
    SubjectThree
  },
  props: {
    open: Boolean,
    toggle: Function,
    dataSemester: Array,
    dataGrade: Array,
    dataSubjects: Array
  },
  data() {
    return {
      subjectsAllStudent: false,
      tempIdStudent: [],
      studentsDataList: [],
      subjectList: [],
      semesterList: [],
      gradeList: [],
      classList: [],
      stepPosition: 1,
      steps: 3,
      validateFormOne: false,
      reset: false,
      resetBySchoolYear: false,
      resetByGrade: false,
      loadingSave: false,
      loadingSubjectThree: false,
      vertical: false,
      altLabels: false,
      minTeacher: false,
      editable: true,
      type: "add",
      subject: {
        hours_week: "",
        lesson: 0,
        lesson_type: 0, // 0 = all student, 1 = some student (for dev)
        sub: 0,
        semester: {},
        school_year: 0,
        kkm: "",
        grade: 0,
        class: [],
        data_employee: [],
        student: []
      },
      teacherList: []
    };
  },
  watch: {
    steps(val) {
      if (this.stepPosition > val) {
        this.stepPosition = val;
      }
    },
    open(newValue) {
      this.reset = !newValue;
      this.semesterList = this.dataSemester;
      this.subjectList = this.dataSubjects;
      this.gradeList = this.dataGrade;
      // this.subject.semester = this.dataSemester.find(d => d.status == 1);
      this.getTeacher();
    }
  },
  methods: {
    onChangeFilter(res) {
      if (res.type === "school_year") {
        this.subject.semester = res.selected[0];
        this.resetBySchoolYear = true;
        this.clearTeacherStudent();
      }
      if (res.type === "select_grade") {
        this.subject.grade = res.filter.grade;
        this.resetByGrade = true;
        this.clearTeacherStudent();
      }
      if (res.type === "select_class") {
        this.subject.class = res.filter.class;
        this.classList = res.selected;
      }
    },
    onChangeSubjects(item) {
      if (item.type === 0) this.subjectsAllStudent = true;
      else this.subjectsAllStudent = false;
      this.subject.sub = item.id_sub;
      this.subject.lesson = item.id_master;
      this.subject.lesson_type = item.type; // 0 = all student, 1 = some student (for dev)
    },
    getTeacher() {
      get_teacher().then(res => (this.teacherList = res.data.data));
    },
    getDataStudent(idClass) {
      this.loadingSubjectThree = true;
      let body = {
        filter: {
          "class_students.class": [idClass],
          "class_students.school_year": [
            this.subject.semester.school_year_master
          ],
          "class_masters.id": [this.subject.grade]
        }
      };
      studentsList(body).then(res => {
        this.studentsDataList = res.data.data;
        this.loadingSubjectThree = false;
      });
    },
    // getClass() {
    //   let body = {
    //     grade: [this.subject.grade],
    //     school_year: [this.subject.semester.id]
    //   };
    //   get_class_list(body).then(res => {
    //     this.classList = res.data.data;
    //   });
    // },
    close() {
      this.toggle();
      this.resetForm();
    },
    setAllClass(param) {
      this.checkAllClass = param;
      if (param) {
        this.subject.class = this.classList.map(r => r.id);
      } else {
        this.subject.class = [];
      }
    },
    // at step 2
    saveTeacher(value) {
      this.subject.data_employee = value;
    },
    // at step 2 & 3
    clearTeacherStudent() {
      this.subject.data_employee = [];
      this.tempIdStudent = [];
      this.teacherList.map(item => (item.disabled = false));
      this.$nextTick(() => {
        this.resetBySchoolYear = false;
        this.resetByGrade = false;
      });
    },
    nextStep(n) {
      if (n === this.steps) {
        this.stepPosition = 1;
      } else {
        switch (n) {
          case 1:
            this.validateFormOne = true;
            if (
              this.subject.hours_week &&
              this.subject.lesson &&
              this.subject.kkm &&
              this.subject.grade &&
              this.subject.class.length != 0
            ) {
              this.stepPosition = n + 1;
              break;
            } else {
              this.$vuetify.goTo(140);
              break;
            }
          default:
            if (this.subject.data_employee.length > 0) {
              this.stepPosition = n + 1;
              this.minTeacher = false;
              break;
            } else {
              this.minTeacher = true;
            }
        }
      }
    },
    prevStep(n) {
      if (n === 1) {
        this.stepPosition = 1;
      } else {
        this.stepPosition = n - 1;
      }
      this.$vuetify.goTo(150);
    },
    resetForm() {
      this.subjectsAllStudent = false;
      this.subject = {
        hours_week: "",
        lesson: 0,
        sub: 0,
        semester: {},
        kkm: "",
        grade: 0,
        class: [],
        data_employee: [],
        student: []
      };
      this.studentsDataList = [];
      this.stepPosition = 1;
      this.reset = true;
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    async saveSubject() {
      this.loadingSave = true;
      const body = JSON.parse(JSON.stringify(this.subject));
      body.school_year = this.subject.semester.id;
      try {
        if (this.subject.lesson_type) {
          let arrayStudent = [];
          this.tempIdStudent.map(d => d.map(r => arrayStudent.push(r.student)));
          body.student = [...new Set(arrayStudent)];
        } //else {
        //   let bodyStudent = {
        //     filter: {
        //       "class_students.class": this.subject.class,
        //       "class_students.school_year": [
        //         this.subject.semester.school_year_master
        //       ],
        //       "class_masters.id": [this.subject.grade]
        //     }
        //   };
        //   body.student = await studentsList(bodyStudent).then(res =>
        //     res.data.data.map(item => item.student)
        //   );
        // }
        const response = await submitSubjects(body);
        if (response.data.code) {
          this.snackBar(
            true,
            this.$i18n.t("subjects.msg_response.subjects.success_added")
          );
          this.close();
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.loadingSave = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.stepper-content {
  padding: 0px !important;
  margin-top: 20px;
}
</style>
