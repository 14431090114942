<template>
  <v-card flat>
    <v-form ref="subjectOne" lazy-validation>
      <v-row class="mt-2">
        <v-col cols="12" sm="6">
          <v-autocomplete
            :rules="[v => !!v || $t('app.required')]"
            :disabled="type == 'detail'"
            :items="subjectList"
            :label="$t('subjects.choose_subjects')"
            :item-text="stringSubject"
            return-object
            dense
            outlined
            required
            @change="changeSubject"
          />
          <v-text-field
            v-model="subject.kkm"
            :rules="[v => !!v || $t('app.required')]"
            :label="$t('subjects.kkm')"
            type="number"
            min="0"
            dense
            outlined
            @keyup="setScore"
          />
          <v-text-field
            v-model="subject.hours_week"
            :rules="[v => !!v || $t('app.required')]"
            :label="$t('subjects.hours_week')"
            type="number"
            min="0"
            dense
            outlined
            @keyup="setScore($event, 'hours')"
          >
            <template slot="append-outer">
              <v-menu open-on-hover left offset-overflow>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-alert-circle-outline
                  </v-icon>
                </template>
                <v-card flat class="pa-2 elevation-0" width="500">
                  <v-img
                    src="https://sistesi.s3-ap-southeast-1.amazonaws.com/petunjuk/informasi-jumlah-mengajar.svg"
                  />
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <FilterSchoolYear
            :triggerChange="open"
            isVertical
            notMultiple
            multipleClass
            @handler="getFilter"
          />
          <!-- <v-select
          v-model="subject.semester.id"
          :rules="[v => !!v || $t('app.required')]"
          :disabled="type == 'detail'"
          :items="semesterList"
          :label="$t('subjects.semester')"
          :item-text="
            item =>
              `${item.start_year} / ${item.end_year}, Semester ${item.semester}`
          "
          item-value="id"
          dense
          outlined
          required
          @change="changeSchoolYear"
        >
          <template #item="{ item }">
            <span>
              {{ item.start_year }} / {{ item.end_year }}, semester
              {{ item.semester }}
            </span>
            <v-tooltip right>
              <template #activator="{ on }">
                <v-icon
                  v-on="on"
                  :color="schoolYearInfo(item.status).color"
                  small
                  class="ml-1"
                  >{{ schoolYearInfo(item.status).name }}</v-icon
                >
              </template>
              <span>{{ schoolYearInfo(item.status).info }}</span>
            </v-tooltip>
          </template>
        </v-select> -->
        </v-col>
        <v-col cols="12" sm="6">
          <!-- <v-text-field
          v-model="subject.kkm"
          :rules="[v => !!v || $t('app.required')]"
          :label="$t('subjects.kkm')"
          type="number"
          dense
          outlined
        /> -->
        </v-col>
        <v-col cols="12" sm="6">
          <!-- <v-select
          v-model="subject.grade"
          :rules="[v => !!v || $t('app.required')]"
          :disabled="type == 'detail'"
          :items="gradeList"
          :label="$t('subjects.grade')"
          item-text="grade"
          item-value="id"
          dense
          outlined
          required
          @change="changeGrade"
        /> -->
        </v-col>
        <v-col cols="12" sm="6">
          <!-- <v-text-field
          v-model="subject.hours_week"
          :rules="[v => !!v || $t('app.required')]"
          :label="$t('subjects.hours_week')"
          type="number"
          dense
          outlined
        /> -->
        </v-col>
        <!-- <v-col v-if="subject.grade" cols="12" sm="6">
        <div class="d-flex">
          <v-select
            v-model="subject.class"
            :rules="[v => v.length > 0 || $t('app.required_array')]"
            :disabled="type == 'detail'"
            :items="classList"
            :label="$t('subjects.for_class')"
            :item-text="item => `${item.name}`"
            item-value="id"
            multiple
            dense
            outlined
            required
          >
            <template v-slot:selection="{ item, index }">
              <span v-if="index === 0" class="mr-2">{{ `${item.name}` }}</span>
              <span v-if="index === 1" class="grey--text">
                (+{{ subject.class.length - 1 }} {{ $t("subjects.others") }})
              </span>
            </template>
            <template slot="prepend-item">
              <v-list-item @click="handleClickAll">
                <v-list-item-action :class="checkAllClass && 'primary--text'">
                  <v-checkbox
                    v-model="checkAllClass"
                    class="caption mt-1 mr-4"
                    @click="handleClickAll"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  {{ $t("app.select_all") }}
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </div>
      </v-col> -->
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import FilterSchoolYear from "../../../components/filterSchooYear";

export default {
  props: {
    type: String,
    open: Boolean,
    subjectList: Array,
    subject: Object,
    semesterList: Array,
    gradeList: Array,
    classList: Array,
    setAllClass: Function,
    resetForm: Boolean,
    validate: Boolean
  },
  components: { FilterSchoolYear },
  data() {
    return {
      checkAllClass: false
    };
  },
  computed: {
    // semesters() {
    //   const getSchoolYearActive = this.semesterList.filter(s => s.status >= 1);
    //   return getSchoolYearActive;
    // }
  },
  watch: {
    checkAllClass(newVal) {
      this.setAllClass(newVal);
    },
    resetForm(newValue) {
      if (newValue) {
        this.$refs.subjectOne.reset();
        this.$refs.subjectOne.resetValidation();
      }
    },
    validate(newValue) {
      if (newValue) {
        this.$refs.subjectOne.validate();
      }
    }
  },
  methods: {
    setScore(event, weekhours) {
      if (event.target.value && Number(event.target.value)) {
        const value = Number(event.target.value);
        if (weekhours) {
          if (value <= 0) {
            this.subject.hours_week = 0;
          } else {
            this.subject.hours_week = event.target.value;
          }
        } else {
          if (value <= 0) {
            this.subject.kkm = 0;
          } else if (value >= 100) {
            this.subject.kkm = 100;
          } else {
            this.subject.kkm = event.target.value;
          }
        }
      } else {
        if (weekhours) this.subject.hours_week = "";
        else this.subject.kkm = "";
      }
    },
    getFilter(res) {
      this.$emit("onChangeFilter", res);
      // if (res.filter.class && res.filter.grade && res.filter.school_year) {
      //   this.modelEntryClass = true;
      // }
      // this.$emit("getData", res);
    },
    schoolYearInfo(status) {
      let icon = { name: "", color: "", info: "" };
      const active = {
        name: "mdi-check-circle-outline",
        color: "success",
        info: this.$i18n.t("school_year.current_school_year")
      };
      const willCome = {
        name: "mdi-timer-sand",
        color: "grey",
        info: this.$i18n.t("school_year.upcoming_school_year")
      };
      switch (status) {
        case 1:
          icon = active;
          break;
        case 2:
          icon = willCome;
          break;
      }
      return icon;
    },
    handleClickAll() {
      this.checkAllClass = !this.checkAllClass;
    },
    changeSubject(item) {
      this.$emit("changeSubjects", item);
    },
    stringSubject: item => `${item.lesson} ${item.sub ? "- " + item.sub : ""}`
    // changeGrade() {
    //   this.$emit("getClass");
    // },
    // changeSchoolYear() {
    //   this.$emit("getClass");
    // }
  }
};
</script>
